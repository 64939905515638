import React, { Fragment } from "react";
import Main from "../components/main";
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap";
import root from "window-or-global";
import { useQuery } from "@apollo/client";
import { getBuildingByCodeQuery, getBuildingByCodeResult, checkServiceAvailabilityQuery } from "../logic/address";
import { useAsyncSetState } from "use-async-setstate";
import ConfettiImage from "../../static/confetti.svg";
import ProviderImage from "../../static/provider.svg";
import TimesWhite from "../../static/times-white.svg";
import { getFormData } from "../components/manager/form";


export default function Index(props) {
  return (
    <Main>
      <IndexPage {...props} />
    </Main>
  );
}

function IndexPage(props) {
  const [showProvider, setShowProvider] = useAsyncSetState(false);
  let building, unit, unitNumber, buildingCode, addressStatus = false;
  if (root?.location) {
    if (!(root?.location?.search)) {
      root.location.href = "/";
      return (<React.Fragment />)
    } else {
      building = getFormData().building;
      unit = getFormData().unit;

      const params = new URLSearchParams(root.location.search);
      unitNumber = params.get("unitno");
      buildingCode = params.get("building");

      if (buildingCode !== building?.code && unit !== unitNumber) {
        root.location.href = "/";
        return (<React.Fragment />)
      }

      try {
        const response = useQuery(checkServiceAvailabilityQuery, {
          variables: {
            buildingCode,
            unitNo: unitNumber,
          },
        });
  
    
        if (response.loading) {
          return (<Fragment />);
        }
  
        if (response?.error) {
          addressStatus = false;
        }
  
        const serviceAvailability =
          response?.data?.classMethods?.Building?.checkServiceAvailability;
  
        if (serviceAvailability) {
          addressStatus = true;
        }
      } catch (err) {
        console.log({err})
        addressStatus = false;
      }
    }
  }

  return (
    <Fragment>
      <SEO title="Select your provider" />
      <Container fluid className="no-gutters">
        <Row className="align-items-center whole-screen">
          <Col xs={12} md={8} lg={5} xl={5} className="col-provider-blue">
            <Container fluid className="text-md-left">
              {!showProvider && building && addressStatus && <GotoProvider
                building={building}
                setShowProvider={setShowProvider}
                unitNumber={unitNumber}
                buildingCode={buildingCode}
              />}
              {!showProvider && building && !addressStatus && <Unavailable
                building={building}
                setShowProvider={setShowProvider}
                unitNumber={unitNumber}
                buildingCode={buildingCode}
              />}
              {showProvider && <Providers
                unitNumber={unitNumber}
                buildingCode={buildingCode}
                {...props}
              />}
            </Container>
          </Col>
          <Col xs={12} md={4} lg={7} xl={7} className="h-100vh d-none d-md-block" style={{
            background: "url('/building.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }} />
        </Row>
      </Container>
    </Fragment>
  );
}

function Providers({ unitNumber, buildingCode }) {
  let query = useQuery(getBuildingByCodeQuery, {
    variables: {
      buildingCode,
    }
  });

  if (query?.loading) {
    return <Fragment />;
  }

  let providers = getBuildingByCodeResult(query);

  // console.log({ providers });

  return (
    <Fragment>
      <Container fluid className="no-gutters">
        <Row>
          <Col className="font-white fw-400 align-items-center mb-4 mt-4">
            <img src={ProviderImage} alt="provider" className="img-location" />
            {"Choose Provider"}
          </Col>
        </Row>
        <Row>
          <Col className="font-white align-items-center mb-4">
            <Row className="provider-container">
              {(providers || []).sort(() => Math.random() - 0.5).filter((p) => !!p?.targetUri).map((provider) => {
                const targetUri = (provider?.targetUri || "").replace("{{buildingCode}}", buildingCode).replace("{{unitno}}", unitNumber);
                return (
                  <Col xs={12} md={4} lg={4} xl={3} align="center">
                    <a
                      href={targetUri} target="_blank"
                      key={provider?.id}
                      className="provider-box"
                    >
                      <img className="provider-img" src={provider?.icon} alt={provider?.name} />
                    </a>
                  </Col>
                );
              })}

            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

function GotoProvider({ setShowProvider, building, unitNumber, buildingCode }) {

  return (
    <Fragment>
      <Container fluid className="no-gutters">
        <Row>
          <Col className="font-white fw-400 align-items-center mb-4">
            <img src={ConfettiImage } alt="confetti-times" className="img-location" />
            {"Congratulations!"}
          </Col>
        </Row>
        <Row>
          <Col className="font-white align-items-center mb-4">
            {"Your address is available"}
          </Col>
        </Row>
        <Row>
          <Col className="font-white building-address align-items-center mb-4">
            {`${unitNumber || ""} - ${building?.formattedAddress || ""}`}
          </Col>
        </Row>
        <Row>
          <Col className="font-white align-items-center mb-4">
            <Button
              className="no-border-radius px-4 py-2"
              onClick={async () => await setShowProvider(true)}
            >
              {"Select Provider"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

function Unavailable({ building, unitNumber }) {
  return (
    <Fragment>
      <Container fluid className="no-gutters">
        <Row>
          <Col className="font-white fw-400 align-items-center mb-4">
            <img src={TimesWhite} alt="confetti-times" className="img-location" />
            {"Unable to continue!"}
          </Col>
        </Row>
        <Row>
          <Col className="font-white align-items-center mb-4">
            {"Your address is not available"}
          </Col>
        </Row>
        <Row>
          <Col className="font-white building-address align-items-center mb-4">
            {`${unitNumber || ""} - ${building?.formattedAddress || ""}`}
          </Col>
        </Row>
        <Row>
          <Col className="font-white align-items-center mb-4">
            <Button
              className="no-border-radius px-4 py-2"
              href="/"
            >
              {"Go Back"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
